<template>
  <modal name="modal-elect-cmp" class="modal-inner modal-elect-cmp" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <span class="close-button" @click="hide('modal-elect-cmp')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      {{ $t("electricity_consumption") }} <span class="text-xs font-light" v-if="alarmInfo">{{ alarmInfo.unit_name }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formElectCmp">
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseInput v-model="powerConsumed" type="number" min="0" :field_name="$t('electricity_consumption')" rules="required|min_value:0" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('modal-elect-cmp')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="setOperatingTime()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 300;

export default {
  name: "ElectCmpUpdate",
  props: {
    alarmInfo: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      powerConsumed: 0,
    };
  },
  watch: {
    alarmInfo(newVal) {
      this.powerConsumed = newVal.powerConsumed;
    },
  },
  methods: {
    hide(id) {
      this.$modal.hide(id);
    },

    setOperatingTime() {
      this.$refs.formElectCmp.validate().then((success) => {
        if (!success) {
          return;
        }

        // convert to operating time
        let operatingTime = Math.round(this.powerConsumed / 0.42);

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/gps/operating-time/${this.alarmInfo.imei_number}`, { operatingTime })
          .then(() => {
            // eslint-disable-line
            this.handleSuccess("Uppdaterad");
            this.$emit("setOperatingTime");
            this.hide("modal-elect-cmp");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    resetForm() {
      this.powerConsumed = 0;
      this.$refs.formElectCmp.reset();
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
