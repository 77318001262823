<template>
  <modal name="modal-temp-alarm" class="modal-inner modal-temp-alarm" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <span class="close-button" @click="hide('modal-temp-alarm')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      {{ $t("high_temperature_alarm") }} <span class="text-xs font-light" v-if="alarmInfo">{{ alarmInfo.unit_name }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formTempAlarm">
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseInput v-model="temperature" type="number" min="10" max="100" :field_name="$t('secure_track.high_temperature')" rules="required|between:10,100" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('modal-temp-alarm')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="setHighTemperature()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 300;

export default {
  name: "TemperatureAlarmThreshold",
  props: {
    alarmInfo: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      temperature: 0,
    };
  },
  watch: {
    alarmInfo(newVal) {
      this.temperature = newVal.highTemp;
    },
  },
  methods: {
    hide(id) {
      this.$modal.hide(id);
    },

    setHighTemperature() {
      this.$refs.formTempAlarm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.$emit("setHighTemperature", { imei: this.alarmInfo.imei_number, temperature: this.temperature, deviceType: this.alarmInfo.type });
      });
    },

    resetForm() {
      this.temperature = 0;
      this.$refs.formTempAlarm.reset();
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
